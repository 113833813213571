import * as React from "react";
import styled from "styled-components";
import axios from "axios";
import { toast } from "react-toastify";
import { generateInvoiceUrl } from "../../library/URLs";
import { UserProfileData } from "../../library/constants";
import { useParams } from "react-router-dom";
import { BsBoxSeam, BsFileText } from "react-icons/bs";

const DHLShipmentContainer = ({
  processor,
  dhlProcessed,
  waybillURL,
  trackingNumber,
  invoiceURL,
  status,
}) => {
  return (
    <div className="col-xl-6 col-md-12">
      <span className="header">Processor Shipment Details</span>
      <div className="card bg-light border-0 shadow">
        <div className="card-body shipper-container">
          <div style={{ width: "100%" }}>
            {dhlProcessed === "Yes" ? (
              <Processed
                trackingNumber={trackingNumber}
                waybillURL={waybillURL}
                invoiceURL={invoiceURL}
                status={status}
              />
            ) : (
              <NotProcessed>
                <p>Oops... Sorry, you are yet to process this shipment</p>
              </NotProcessed>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const NotProcessed = styled.div`
  height: 365px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconText = styled.div`
  font-size: 14px;
  color: #008561;
`

export const Processed = ({
  waybillURL,
  trackingNumber,
  invoiceURL,
  status,
}) => {
  const params = useParams();
  const shipmentID = params["shipmentID"];
  const [loading, setLoading] = React.useState(false);
  const [subPdf, setSubPdf] = React.useState(null);

  // Function to clean up the URL
  const cleanUrl = (url) => {
    const redundantPart =
      "https://parcelflow-user-backend-7948506154d4.herokuapp.com//";
    return url.replace(redundantPart, "");
  };

  // Function to handle the download
  // const downloadPdf = async (pdfUrl) => {
  //   const response = await fetch(pdfUrl, { responseType: 'blob' });

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }

  //     const blob = await response.blob();
  //     const url = window.URL.createObjectURL(blob);
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', "invoice" || 'downloaded.pdf'); // Set default filename if not provided

  //     document.body.appendChild(link);
  //     link.click();

  //     document.body.removeChild(link);
  //     window.URL.revokeObjectURL(url);
  // };

  const generateInvoice = () => {
    let info = {
      transaction_id: "62885l10a8+2700+44+2.3.0+13-2-2023 9:49",
      company_id: UserProfileData().company_id,
    };
    let newData = { ...info, shipment_id: shipmentID };
    setLoading(true);
    axios
      .post(generateInvoiceUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        if (response["data"]["success"] === false) {
          setLoading(false);
          return;
        }

        let cleanedUrl = cleanUrl(response["data"]);

        setSubPdf(cleanedUrl);

        toast.success("Invoice generated! Click link to open");
        setLoading(false);
      });
  };

  const downloadFile = (url) => {
    const headers = {
      "Content-Type": "application/pdf",
    };
    console.log("hello");
    // url = "https://admin.parcelflow.io/engine/v1/actions/get-shipment-invoice.php"
    axios({
      url: url, //your url
      method: "GET",
      headers: headers, // important
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "file.pdf"); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });

    // return false;
  };
  return (
    <div>
      <div className="mb-3" >
        <span className="title">Tracking Number</span>
        {
          trackingNumber?.split("|")?.map((tracking_num, id) => (
            <span key={id} className="d-flex align-items-center my-2 gap-2">
              <IconText><BsBoxSeam className="icon-text" /></IconText>
              <p className="m-0" >
                {tracking_num}
              </p>
            </span>
          ))
        }
      </div>
      <div>
        <span className="title">Waybill</span>
        {status === "Cancelled" ? (
          <p>Shipment has been cancelled and waybill can't be viewed</p>
        ) : waybillURL?.split("|")?.map((docUrl, id) => (
          <span key={id} className="d-flex align-items-center my-2 gap-2">
            <IconText>
              <BsFileText />
            </IconText>
            <p className="m-0" >
              Click{" "}
              <a
                style={{ textDecoration: "none" }}
                target="_blank"
                href={docUrl}
                rel="noreferrer"
              >
                here
              </a>{" "}
              to view the waybill
            </p>
          </span>
        ))}
      </div>
      <div className="d-flex justify-content-between">
        <div>
          <span className="title">Invoice</span>
          <span className="d-flex align-items-center my-2 gap-2">
            <IconText>
              <BsFileText />
            </IconText>
            <p className="m-0" >
              Click{" "}
              <a
                style={{ textDecoration: "none" }}
                target="_blank"
                rel="noreferrer"
                href={subPdf ? subPdf : invoiceURL}
              >
                here
              </a>{" "}
              to view the invoice
            </p>
          </span>
        </div>
        <button
          style={{ height: "fit-content" }}
          onClick={generateInvoice}
          className="confirm-button py-2"
        >
          {loading ? (
            <span className="spinner-border spinner-grow-sm" />
          ) : (
            "RE-GENERATE INVOICE"
          )}
        </button>
      </div>
    </div>
  );
};

export default DHLShipmentContainer;
