import "../styles/home.scss";
import "../styles/single-shipment.scss";
import "../styles/components.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import ShipmentTimeline from "./Widgets/ShipmentTimeline";
import { useEffect, useState } from "react";
import ShipmentInformation from "./Widgets/ShipmentInformation";
import { getShipmentDetailsURL, getShipmentLevelsUrl } from "../library/URLs";
import axios from "axios";
import { UserProfileData } from "../library/constants";
import {
  ErrorNotification,
  SuccessNotification,
} from "../components/Notifications";
import ShipmentPath from "./Widgets/ShipmentPath";
import DHLShipmentContainer from "./Widgets/DHLShipmentContainer";
import RouteShipmentDialog from "../modals/RouteShipmentDialog";
import CancelShipmentDialog from "../modals/CancelShipmentDialog";
import ReRouteShipmentDialog from "../modals/ReRouteShipmentDialog";
import moment from "moment";
import ApproveTerminalShipmentDialog from "../modals/ApproveTerminalShipmentDialog";
import * as React from "react";
import styled from "styled-components";
import { SessionManagement } from "../library/SessionManagement";
import SubscriptionInactive from "../components/SubscriptionInactive";
import UpdateShipmentStatusDialog from "../modals/UpdateShipmentStatusDialog";
import EditShipmentDetailsDialog from "../modals/EditShipmentDetailsDialog";
import UpdateWaybillDialog from "../modals/UpdateWaybillDialog";
import ParcelImages from "./Widgets/ParcelImages";

const LoadingContainer = styled.div`
  height: 155px;
  text-align: center;
  display: flex;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 5px;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const SingleShipment = () => {
  const [showApproveShipmentDialog, setShowApproveShipmentDialog] =
    useState(false);

  const [isRetrievingDetails, setIsRetrievingDetails] = useState(true);

  const params = useParams();
  const navigator = useNavigate();
  const shipmentID = params["shipmentID"];

  const [
    isShowRouteShipmentDialogVisible,
    setShowRouteShipmentDialogVisibility,
  ] = useState(false);
  const [
    isShowReRouteShipmentDialogVisible,
    setShowReRouteShipmentDialogVisibility,
  ] = useState(false);
  const [
    isShowCancelShipmentDialogVisible,
    setShowCancelShipmentDialogVisibility,
  ] = useState(false);
  const [
    isShowUpdateShipmentStatusDialogVisible,
    setShowUpdateShipmentStatusDialogVisibility,
  ] = useState(false);

  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showUpdateWaybill, setShowUpdateWaybill] = useState(false);

  const [shipmentTime, setShipmentTime] = useState("");
  const [pickupLocation, setPickupLocation] = useState("");
  const [dropoffLocation, setDropoffLocation] = useState("");
  const [senderID, setSenderID] = useState("");
  const [senderType, setSenderType] = useState("");
  const [senderName, setSenderName] = useState("");
  const [senderPhoneNumber, setSenderPhoneNumber] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [receiverName, setReceiverName] = useState("");
  const [receiverPhoneNumber, setReceiverPhoneNumber] = useState("");
  const [receiverEmail, setReceiverEmail] = useState("");
  const [amount, setAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [status, setStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [statusClass, setStatusClass] = useState("");
  const [description, setDescription] = useState("");
  const [station, setStation] = useState("");
  const [shipmentNumber, setShipmentNumber] = useState("");
  const [shipmentType, setShipmentType] = useState("");
  const [shipmentPlan, setShipmentPlan] = useState("");
  const [dropoffCoordinates, setDropoffCoordinates] = useState("");
  const [pickupCoordinates, setPickupCoordinates] = useState("");
  const [approvedBy, setApprovedBy] = useState("");
  const [weight, setWeight] = useState("");
  const [riderName, setRiderName] = useState("");
  const [itemValue, setItemValue] = useState("");
  const [itemValueCurrency, setItemValueCurrency] = useState("");
  const [currency, setCurrency] = useState("");
  const [senderCountry, setSenderCountry] = useState("");
  const [senderState, setSenderState] = useState("");
  const [senderCity, setSenderCity] = useState("");
  const [senderPostcode, setSenderPostcode] = useState("");
  const [receiverCountry, setReceiverCountry] = useState("");
  const [receiverState, setReceiverState] = useState("");
  const [receiverCity, setReceiverCity] = useState("");
  const [receiverPostcode, setReceiverPostcode] = useState("");

  const [dhlProcessed, setDHLProcessed] = useState("");
  const [processor, setProcessor] = useState("");
  const [waybillURL, setWaybillURL] = useState("");
  const [invoiceURL, setInvoiceURL] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [pickupFee, setPickupFee] = useState("0");
  const [requestedPickup, setRequestedPickup] = useState("No");
  const [pickupRequestStatus, setPickupRequestStatus] = useState("Not Picked");
  const [shipmentLevels, setShipmentLevels] = useState([]);
  const [multiLevel, setMultiLevel] = useState("No");
  const [isDiscrepancy, setIsDiscrepancy] = useState("");
  const [weightDiscrepancy, setWeightDiscrepancy] = useState("");
  const [insurance, setInsurance] = useState("");
  const [parcelImg, setParceImg] = useState("");

  const toggleRouteShipmentDialog = (type) => {
    type === "open"
      ? setShowRouteShipmentDialogVisibility(true)
      : setShowRouteShipmentDialogVisibility(false);
  };

  const toggleReRouteShipmentDialog = (type) => {
    type === "open"
      ? setShowReRouteShipmentDialogVisibility(true)
      : setShowReRouteShipmentDialogVisibility(false);
  };

  const toggleCancelShipmentDialog = (type) => {
    type === "open"
      ? setShowCancelShipmentDialogVisibility(true)
      : setShowCancelShipmentDialogVisibility(false);
  };

  const toggleUpdateShipmentDialog = (type) => {
    type === "open"
      ? setShowUpdateShipmentStatusDialogVisibility(true)
      : setShowUpdateShipmentStatusDialogVisibility(false);
  };

  const data = {
    shipment_id: shipmentID,
  };

  const newData = { ...data, ...UserProfileData() };

  const toggleApproveTerminalShipment = (type) => {
    type === "open"
      ? setShowApproveShipmentDialog(true)
      : setShowApproveShipmentDialog(false);
  };

  const openEditDialog = () => {
    setShowEditDialog(true);
  };

  const closeEditDialog = () => {
    setShowEditDialog(false);
  };

  const openUpdateWaybillDialog = () => {
    setShowUpdateWaybill(true);
  };

  const closeUpdateWaybillDialog = () => {
    setShowUpdateWaybill(false);
  };

  const goToSender = () => {
    if (senderType === "Customer") {
      navigator("/customer/" + senderID);
    } else if (senderType === "Admin") {
      navigator("/admin/" + senderID);
    }
  };

  const reCreateShipment = () => {
    navigator("/duplicate-shipment/" + shipmentID);
  };

  const fetchShipmentLevels = () => {
    axios
      .post(getShipmentLevelsUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response["data"]["success"] === false) return;

        const shipmentDetails = response["data"];

        const levels = shipmentDetails["data"];
        setShipmentLevels(levels);
      });
  };

  const fetchShipmentInfo = () => {
    axios
      .post(getShipmentDetailsURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        setIsRetrievingDetails(false);
        if (response["data"]["success"] === false) return;

        const shipmentDetails = response["data"];

        const sender = shipmentDetails["data"]["user_id"];
        const senderType = sender.toString().split("-")[0];
        const senderID = sender.toString().split("-")[1];

        const senderName = shipmentDetails["data"]["sender_name"];
        const senderPhoneNumber = shipmentDetails["data"]["sender_phone_no"];
        const receiverName = shipmentDetails["data"]["recipient_name"];
        const receiverPhoneNumber =
          shipmentDetails["data"]["recipient_phone_no"];
        const amount = shipmentDetails["data"]["total_amount"];
        const shippingCurrency = shipmentDetails["data"]["currency"];
        const paymentMethod = shipmentDetails["data"]["payment_method"];
        const pickupLocation = shipmentDetails["data"]["pick_up_location"];
        const dropoffLocation = shipmentDetails["data"]["drop_off_location"];
        const status = shipmentDetails["data"]["status"];
        const paymentStatus = shipmentDetails["data"]["paid_status"];
        const description = shipmentDetails["data"]["item_name"];
        const riderName = shipmentDetails["data"]["rider_name"];
        const itemValue = shipmentDetails["data"]["item_value"];
        const itemValueCurrency =
          shipmentDetails["data"]["item_value_currency"];
        const weight = shipmentDetails["data"]["weight"];
        const shipmentStation =
          shipmentDetails["data"]["processing_station_name"];
        const shipmentType = shipmentDetails["data"]["shipment_type"];
        const shipmentPlan = shipmentDetails["data"]["shipment_plan"];
        const senderEmail = shipmentDetails["data"]["sender_email"];
        const receiverEmail = shipmentDetails["data"]["recipient_email"];
        const shipmentNumber = shipmentDetails["data"]["shipment_no"];
        const sender_country = shipmentDetails["data"]["sender_country"]["name"];
        const sender_state = shipmentDetails["data"]["sender_state"];
        const sender_city = shipmentDetails["data"]["sender_city"];
        const sender_postcode = shipmentDetails["data"]["sender_postcode"];
        const receiver_country = shipmentDetails["data"]["receiver_country"];
        const receiver_state = shipmentDetails["data"]["receiver_state"];
        const receiver_city = shipmentDetails["data"]["receiver_city"];
        const receiver_postcode = shipmentDetails["data"]["receiver_postcode"];

        const dropoffCoordinates =
          shipmentDetails["data"]["drop_off_latitude"] +
          "," +
          shipmentDetails["data"]["drop_off_longitude"];
        const pickupCoordinates =
          shipmentDetails["data"]["pick_up_latitude"] +
          "," +
          shipmentDetails["data"]["pick_up_longitude"];

        const approvedBy = shipmentDetails["data"]["approved_by"];

        const dhlProcessed = shipmentDetails["data"]["dhl_processed"];
        const processor = shipmentDetails["data"]["processor"];
        const waybillURL = shipmentDetails["data"]["pdf_document"];
        const invoiceURL = shipmentDetails["data"]["invoice_url"];
        const trackingNumber =
          shipmentDetails["data"]["shipment_tracking_number"];

        const timestamp = shipmentDetails["data"]["date_time"];
        const shipmentTime = moment(timestamp).format("ddd MMM Do, h:mm A");
        const requested_pickup = shipmentDetails["data"]["requested_pickup"];
        const pickup_fee = shipmentDetails["data"]["pickup_fee"];
        const requested_pickup_status =
          shipmentDetails["data"]["pickup_request_status"];

        const is_discrepancy = shipmentDetails["data"]["is_discrepancy"];
        const weight_discrepancy = shipmentDetails["data"]["dhl_weight"];
        const insurance = shipmentDetails["data"]["insurance"];
        const parcel_img = shipmentDetails["data"]["parcel_img_url"];

        setShipmentTime(shipmentTime);

        const statusClass = status.toString().replaceAll(" ", "");
        setStatusClass(statusClass);

        if (processor === "TerminalAfrica") {
          setPickupCoordinates("6.596413,3.353785");
          setDropoffCoordinates("6.614775,3.356861");
        } else {
          setPickupCoordinates(pickupLocation);
          setDropoffCoordinates(dropoffLocation);
        }

        setSenderID(senderID);
        setSenderType(senderType);
        setSenderName(senderName);
        setSenderPhoneNumber(senderPhoneNumber);
        setReceiverName(receiverName);
        setAmount(amount);
        setReceiverPhoneNumber(receiverPhoneNumber);
        setSenderEmail(senderEmail);
        setReceiverEmail(receiverEmail);
        setPaymentMethod(paymentMethod);

        console.log("Payment status is " + paymentStatus);
        setStatus(status);
        setPaymentStatus(paymentStatus);
        setDescription(description);
        setShipmentType(shipmentType);
        setShipmentPlan(shipmentPlan);
        setApprovedBy(approvedBy);
        setPickupLocation(pickupLocation);
        setDropoffLocation(dropoffLocation);
        setShipmentNumber(shipmentNumber);
        setRiderName(riderName);
        setItemValue(itemValue === undefined ? "" : itemValue);
        setItemValueCurrency(
          itemValueCurrency === undefined ? "" : itemValueCurrency
        );
        setWeight(weight === undefined ? "" : weight);
        setDHLProcessed(dhlProcessed);
        setProcessor(processor);
        setWaybillURL(waybillURL);
        setInvoiceURL(invoiceURL);
        setTrackingNumber(trackingNumber);
        setStation(shipmentStation);
        setCurrency(shippingCurrency === undefined ? "" : shippingCurrency);
        setPickupFee(pickup_fee ?? "0");
        setRequestedPickup(requested_pickup);
        setPickupRequestStatus(requested_pickup_status);
        setIsDiscrepancy(is_discrepancy);
        setWeightDiscrepancy(weight_discrepancy);
        setInsurance(insurance);
        setParceImg(parcel_img);
        setSenderCountry(sender_country);
        setSenderState(sender_state);
        setSenderCity(sender_city);
        setSenderPostcode(sender_postcode);
        setReceiverCountry(receiver_country);
        setReceiverState(receiver_state);
        setReceiverCity(receiver_city);
        setReceiverPostcode(receiver_postcode);
      });
  };

  useEffect(() => {
    fetchShipmentInfo();
    fetchShipmentLevels();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />

          <div className="d-flex flex-row justify-content-between mb-2 mx-3">
            <h5 className="header">Shipment Detail - {shipmentNumber}</h5>
            <span>{shipmentTime}</span>
          </div>

          <div className="d-flex flex-row mx-3 justify-content-between">
            {status === "Pending Approval" ? (
              shipmentLevels?.length > 1 ? (
                shipmentLevels[0]?.shipment_type === "Local" &&
                shipmentLevels[0]?.approved === "No" ? (
                  <div>
                    <button
                      style={{ marginRight: 15 }}
                      className="confirm-button"
                      onClick={(e) => {
                        toggleRouteShipmentDialog("open");
                        setMultiLevel("Yes");
                      }}
                    >
                      ROUTE SHIPMENT
                    </button>
                    <button
                      onClick={(e) => toggleCancelShipmentDialog("open")}
                      className="cancel-red-button"
                    >
                      CANCEL SHIPMENT
                    </button>
                  </div>
                ) : (
                  <div>
                    <button
                      style={{ marginRight: 15 }}
                      className="confirm-button"
                      onClick={(e) => toggleReRouteShipmentDialog("open")}
                    >
                      RE-ROUTE SHIPMENT
                    </button>
                    <button
                      style={{ marginRight: 15 }}
                      className="confirm-button"
                      onClick={(e) => toggleApproveTerminalShipment("open")}
                    >
                      APPROVE SHIPMENT
                    </button>
                    <button
                      onClick={(e) => toggleCancelShipmentDialog("open")}
                      className="cancel-red-button"
                    >
                      CANCEL SHIPMENT
                    </button>
                  </div>
                )
              ) : shipmentLevels[0]?.shipment_type === "Local" &&
                processor === "Self" ? (
                <div>
                  <button
                    style={{ marginRight: 15 }}
                    className="confirm-button"
                    onClick={(e) => toggleRouteShipmentDialog("open")}
                  >
                    ROUTE SHIPMENT
                  </button>
                  <button
                    onClick={(e) => toggleCancelShipmentDialog("open")}
                    className="cancel-red-button"
                  >
                    CANCEL SHIPMENT
                  </button>
                </div>
              ) : shipmentType !== "Local" ? (
                <div>
                  <button
                    style={{ marginRight: 15 }}
                    className="confirm-button"
                    onClick={(e) => toggleApproveTerminalShipment("open")}
                  >
                    APPROVE SHIPMENT
                  </button>
                  <button
                    onClick={(e) => toggleCancelShipmentDialog("open")}
                    className="cancel-red-button"
                  >
                    CANCEL SHIPMENT
                  </button>
                </div>
              ) : (
                <div>
                  <button
                    style={{ marginRight: 15 }}
                    className="confirm-button"
                    onClick={(e) => toggleRouteShipmentDialog("open")}
                  >
                    ROUTE SHIPMENT
                  </button>
                  <button
                    onClick={(e) => toggleCancelShipmentDialog("open")}
                    className="cancel-red-button"
                  >
                    CANCEL SHIPMENT
                  </button>
                </div>
              )
            ) : processor === "Self" &&
              (status === "Pending Pickup" ||
                status === "Going To Pickup" ||
                status === "Picked Up" ||
                status === "Going To Deliver") ? (
              <div>
                <button
                  style={{ marginRight: 15 }}
                  className="confirm-button"
                  onClick={(e) => toggleReRouteShipmentDialog("open")}
                >
                  RE-ROUTE SHIPMENT
                </button>
                <button
                  onClick={(e) => toggleCancelShipmentDialog("open")}
                  className="cancel-red-button"
                >
                  CANCEL SHIPMENT
                </button>
              </div>
            ) : status !== "Cancelled" ? (
              <button
                onClick={(e) => toggleCancelShipmentDialog("open")}
                className="cancel-red-button"
              >
                CANCEL SHIPMENT
              </button>
            ) : (
              <div></div>
            )}

            <div className="d-flex flex-row">
              {processor === "Self" &&
                (status !== "Pending Approval" || status !== "Delivered") && (
                  <div>
                    <button
                      style={{ marginRight: 15 }}
                      className="confirm-button"
                      onClick={(e) => toggleUpdateShipmentDialog("open")}
                    >
                      UPDATE STATUS
                    </button>
                  </div>
                )}
              <div className={`status ${statusClass}`}>{status}</div>
              {UserProfileData().company_id === "65" &&
                processor === "Ella Logistics Cargo" &&
                status !== "Delivered" && (
                  <button
                    style={{ marginLeft: 15 }}
                    onClick={(e) => toggleUpdateShipmentDialog("open")}
                    className="confirm-button"
                  >
                    UPDATE STATUS
                  </button>
                )}
              {UserProfileData().company_id === "99" &&
                status === "Pending Approval" && (
                  <button
                    style={{ marginLeft: 15 }}
                    onClick={openEditDialog}
                    className="confirm-button"
                    type="button"
                  >
                    EDIT SHIPMENT
                  </button>
                )}
              {shipmentType !== "Local" &&
                (UserProfileData().company_id === "99" ||
                  UserProfileData().company_id === "65") &&
                (status !== "Pending Approval" || status === "Cancelled") && (
                  <button
                    style={{ marginLeft: 15 }}
                    onClick={openUpdateWaybillDialog}
                    className="confirm-button"
                    type="button"
                  >
                    UPDATE WAYBILL
                  </button>
                )}
              {shipmentType === "International" ||
              shipmentType === "Interstate" ? (
                <button
                  style={{ marginLeft: 15 }}
                  onClick={(e) => reCreateShipment()}
                  className="confirm-button"
                >
                  RE-CREATE SHIPMENT
                </button>
              ) : null}
            </div>
          </div>

          <ApproveTerminalShipmentDialog
            shipmentID={shipmentID}
            isModalVisible={showApproveShipmentDialog}
            handleCloseDialog={(e) => toggleApproveTerminalShipment("close")}
            fetchShipmentInfo={fetchShipmentInfo}
          />

          <RouteShipmentDialog
            shipmentID={shipmentID}
            multiLevel={multiLevel}
            isModalVisible={isShowRouteShipmentDialogVisible}
            handleCloseDialog={(e) => toggleRouteShipmentDialog("close")}
          />

          <ReRouteShipmentDialog
            shipmentID={shipmentID}
            isModalVisible={isShowReRouteShipmentDialogVisible}
            handleCloseDialog={(e) => toggleReRouteShipmentDialog("close")}
          />

          <CancelShipmentDialog
            shipmentID={shipmentID}
            isModalVisible={isShowCancelShipmentDialogVisible}
            handleCloseDialog={(e) => toggleCancelShipmentDialog("close")}
            fetchShipmentInfo={fetchShipmentInfo}
          />

          <UpdateShipmentStatusDialog
            shipmentID={shipmentID}
            isModalVisible={isShowUpdateShipmentStatusDialogVisible}
            handleCloseDialog={(e) => toggleUpdateShipmentDialog("close")}
            fetchShipmentInfo={fetchShipmentInfo}
            status={status}
          />

          <EditShipmentDetailsDialog
            shipment_number={shipmentNumber}
            isModalVisible={showEditDialog}
            handleCloseDialog={closeEditDialog}
            sender_name={senderName}
            sender_email={senderEmail}
            sender_phone={senderPhoneNumber}
            receiver_name={receiverName}
            receiver_email={receiverEmail}
            receiver_phone={receiverPhoneNumber}
            sender_country={senderCountry}
            sender_state={senderState}
            sender_city={senderCity}
            sender_postcode={senderPostcode}
            receiver_country={receiverCountry}
            receiver_state={receiverState}
            receiver_city={receiverCity}
            receiver_postcode={receiverPostcode}
          />

          <UpdateWaybillDialog
            isModalVisible={showUpdateWaybill}
            handleCloseDialog={closeUpdateWaybillDialog}
            shipment_number={shipmentNumber}
          />

          <div className="container-fluid">
            <div className="row mt-4">
              <div className="col-xl-6 col-md-12">
                <span className="header">Sender Details</span>
                {isRetrievingDetails === true ? (
                  <LoadingContainer>
                    <LoadingSpinner className="spinner-border spinner-grow-sm" />
                  </LoadingContainer>
                ) : (
                  <div className="card bg-light border-0 shadow">
                    <div className="card-body shipper-container">
                      <div className="d-flex justify-content-between">
                        <div>
                          <span className="title">Name</span>
                          <p
                            onClick={(e) => goToSender()}
                            className="sender-name"
                          >
                            {senderName}
                          </p>
                        </div>
                        <div>
                          <span className="title">Phone Number</span>
                          <p>{senderPhoneNumber}</p>
                        </div>
                      </div>
                      <div className="mt-1">
                        <span className="title">Email</span>
                        <p>{senderEmail}</p>
                      </div>
                      <div className="mt-4">
                        <span className="title">Pickup Location</span>
                        <p>{pickupLocation}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-xl-6 col-md-12">
                <span className="header">Receiver Details</span>
                {isRetrievingDetails === true ? (
                  <LoadingContainer>
                    <LoadingSpinner className="spinner-border spinner-grow-sm" />
                  </LoadingContainer>
                ) : (
                  <div className="card bg-light border-0 shadow">
                    <div className="card-body shipper-container">
                      <div className="d-flex justify-content-between">
                        <div>
                          <span className="title">Name</span>
                          <p>{receiverName}</p>
                        </div>
                        <div>
                          <span className="title">Phone Number</span>
                          <p>{receiverPhoneNumber}</p>
                        </div>
                      </div>
                      <div className="mt-1">
                        <span className="title">Email</span>
                        <p>{receiverEmail}</p>
                      </div>
                      <div className="mt-4">
                        <span className="title">Dropoff Location</span>
                        <p>{dropoffLocation}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="row mt-4">
              <ShipmentInformation
                isRetrieving={isRetrievingDetails}
                amount={amount}
                currency={currency}
                paymentStatus={paymentStatus}
                description={description}
                approvedBy={approvedBy}
                station={station}
                weight={weight}
                processor={processor === "Self" ? riderName : processor}
                itemValue={itemValue}
                itemValueCurrency={itemValueCurrency}
                paymentMethod={paymentMethod}
                shipmentID={shipmentID}
                shipmentNumber={shipmentNumber}
                pickupFee={pickupFee}
                requestedPickup={requestedPickup}
                pickupRequestStatus={pickupRequestStatus}
                shipmentType={shipmentType}
                shipmentPlan={shipmentPlan}
                isDiscrepancy={isDiscrepancy}
                weightDiscrepancy={weightDiscrepancy}
                insurance={insurance}
              />
            </div>

            <div className="row mt-4 gy-4">
              {processor === "Self" ? null : (
                // <ShipmentPath
                //     pickupCoordinates={pickupCoordinates}
                //     dropoffCoordinates={dropoffCoordinates}
                // />
                <DHLShipmentContainer
                  processor={processor}
                  dhlProcessed={dhlProcessed}
                  waybillURL={waybillURL}
                  trackingNumber={trackingNumber}
                  invoiceURL={invoiceURL}
                  status={status}
                />
              )}

              {UserProfileData().company_id === "99" && (
                <ParcelImages parcelImages={parcelImg} />
              )}
              <ShipmentTimeline shipmentID={shipmentID} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleShipment;
